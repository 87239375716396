html,
body {
  margin: 0;
  padding: 0;
}

body > #root {
  box-sizing: border-box;
  height: 100%;
  padding: 2rem;
}

/* Set preset ranges to the right because they don't fit in a footer */
.date-picker-dropdown .ant-picker-footer {
  border-left: 1px solid #f0f0f0;
  display: inline-flex;
  min-width: auto;
}
